/* tslint:disable */
/* eslint-disable */
/**
 * aquaway-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './service/account-api';
export * from './service/authenticate-api';
export * from './service/group-api';
export * from './service/group-plan-api';
export * from './service/location-api';
export * from './service/mail-api';
export * from './service/registration-api';
export * from './service/users-api';
export * from './service/workout-plan-api';

